.feature-table {
	width: 85%;
	thead > tr > th {
		border: none;
		text-align: center;
	}
	tbody {
		tr {
			&.striped {
				td {
					background-color: #e1ecf380;
				}
			}
			&.sub-header {
				font-weight: 900;
				font-size: 12pt;
				td {
					padding-top: 30px;
					background-color: transparent !important;
				}
			}
		}
		td {
			border: none;
			padding: 0.75rem;
			text-align: center;
			&:first-of-type {
				border-radius: 6px 0 0 6px;
				text-align: start;
				width: max-content;
			}
			&:last-of-type {
				border-radius: 0 6px 6px 0;
			}
			&.single {
				border-radius: 6px;
				text-align: center;
			}
			svg {
				height: 16px;
				color: #3f65f1;
			}
		}
	}
}
