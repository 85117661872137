.company-users {
	width: max-content;

	.invite-user {
		justify-content: space-between;
		padding: 10px 0px;

		input {
			flex: 1;
			margin-right: 20px;
		}
	}
}

.brand-modal {
	input {
		padding: 6px;
		border-radius: 6px;
	}
}

.company-brand-list {
	@extend .clean-table;
	.remove-brand {
		box-shadow: none;
		background-color: #e85a73;
		border-color: #e85a73;
		height: 35px;
		width: 35px;
		padding: 3px;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			color: #fff;
			height: 20px;
		}
		cursor: pointer;
		&:active {
			svg {
				height: 20px;
			}
		}
	}

	.edit-brand {
		position: absolute;
		right: 20px;
		cursor: pointer;
		height: 24px;
		&:active {
			height: 20px;
		}
	}

	.company-brand-item {
		margin: 10px;
		&:hover {
			background-color: #e3e3e3;
		}
	}

	.add-brand {
		justify-content: space-between;
		padding: 20px;

		input {
			width: 75%;
		}
	}
}

.market-pref {
	.market-tag {
		position: relative;
		margin-right: 8px;
		padding: 6px 12px;
		border-radius: 6px;
		background-color: #3f65f1;
		color: #fff;
		.remove-pref {
			border-radius: 50%;
			background-color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 20px;
			height: 20px;
			position: absolute;
			top: -8px;
			left: calc(100% - 12px);
			border: 1px solid #e1ecf3;
			z-index: 10;
			svg {
				color: #e85a73;
			}
		}
	}
}
