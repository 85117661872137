.pagination-wrapper {
	position: absolute;
	right: 50px;
	.nav-lbl,
	.page-item .page-link {
		font-weight: 700;
		font-size: 16px;
		text-align: center;
		color: rgba(20, 28, 63, 0.5);
	}
	.pagination {
		align-items: center;
		justify-content: center;
		.previous-sec,
		.next-sec {
			.page-link {
				padding: 0;
				border: none;
				&:hover {
					background: none;
					border-bottom: 2px solid transparent !important;
				}
				svg {
					width: 18px;
					height: 18px;
					margin-bottom: 2px;
				}
				&:not([disabled]) {
					background: none;
					.nav-lbl {
						color: #141c3f;
					}
					svg {
						width: 18px;
						height: 18px;
						path {
							stroke-opacity: 1;
						}
					}
					transition: all 0.5s;
				}
			}
		}
		.page-item {
			.page-link {
				border: 0;
				border-bottom: 2px solid transparent;
				color: #141c3f;
				padding: 0 5px;
				margin: 0 8px;
				&:hover {
					background: none;
					border-bottom: 2px solid #141c3f;
				}
			}
			&.active {
				.page-link {
					background: none;
					color: #141c3f;
					border-bottom: 2px solid #141c3f;
				}
			}
		}
	}
}

.pagination-wrapper_remover {
	position: relative !important;
	right: 0px !important;
}
