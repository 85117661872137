.all-ingredients {
	.all-ingredients-list {
		display: block;
		height: 50vh;
		padding: 0;

		div.ingredient-item {
			display: inline-block;
			position: relative;
			margin: 10px 20px 10px 0;
			width: 370px;
			padding: 12px 8px;
		}
	}
}

.react-confirm-alert-body {
	h1 {
		font-size: 23px;
	}
}
